export default {
  en: {
    tokenInFreeze: 'INSTANT Gold Token in Freeze',
    accountTransactions: 'INSTANT Token Account Transactions',
    withdrawLink: 'Withdraw',
    inputLabelAmount: 'Amount in Tokens',
    inputPlaceholderAmount: 'how much tokens?',
    oneToGold: 'INSTANT One to INSTANT Gold',
    goldToOne: 'INSTANT Gold to INSTANT One',
    amountTokensTitle: 'Amount in Tokens',
    amountTokensPlaceholder: 'How much tokens?',
    willGetTitle: 'You will get',
    willGetPlaceholder: 'Amount of tokens you will receive',
    exchangeFixTokens: 'Exchange INSTANT Tokens',
    defrostDate: 'Defrost Date',
  },
  ru: {
    tokenInFreeze: 'INSTANT Gold Токены в заморозке',
    accountTransactions: 'Транзакции по INSTANT Token Account',
    withdrawLink: 'Вывод',
    oneToGold: 'INSTANT One в INSTANT Gold',
    goldToOne: 'INSTANT Gold в INSTANT One',
    amountTokensTitle: 'Сумма в токенах',
    amountTokensPlaceholder: 'Сколько токенов?',
    willGetTitle: 'Вы получите',
    willGetPlaceholder: 'Количество токенов, которые вы получите',
    exchangeFixTokens: 'Обменять INSTANT Токены',
    defrostDate: 'Дата размораживания',
  },
  es: {
    tokenInFreeze: 'Interŝanĝo INSTANT Gold Token in Freeze',
    accountTransactions: 'Transacciones de cuentas de tokens FIX',
    withdrawLink: 'Retirar',
    oneToGold: 'INSTANT One a INSTANT Gold',
    goldToOne: 'INSTANT Gold a INSTANT One',
    amountTokensTitle: 'Cantidad en fichas',
    amountTokensPlaceholder: 'Cuántas fichas?',
    willGetTitle: 'Usted obtendrá',
    willGetPlaceholder: 'Cantidad de fichas que recibirá',
    exchangeFixTokens: 'Intercambio de fichas FIX',
    defrostDate: 'Fecha de descongelación',
  }
}
