<template>
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex-1 flex flex-col min-h-0 bg-white dark:bg-backgroundBlack">
      <div class="flex items-center h-16 flex-shrink-0 pl-5">
        <img class="h-8 w-auto" :src="logo" alt="Workflow"/>
      </div>
      <hr class="px-5 self-center w-10/12 h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700">
      <div class="flex-1 flex flex-col overflow-y-auto scroll">
        <nav class="flex-1 py-4 space-y-1">
          <div
            v-for="item in navigation"
            :key="item.name"
            :class="[
                    $route.path === '/' + item.href ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
            <router-link
              v-if="!item.global"
              :to=" '/' + item.href"
              class="w-full flex px-2 py-2 items-center"
              v-show="!item.children || children"
              @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
            >
              <component
                v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy'"
                :is="item.icon"
                :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
              />
              {{ t(item.name) }}
            </router-link>
            <a
              v-if="item.name === 'cryptohouse'"
              href="#"
              @click.prevent="checkout2Lvl"
              class="group flex items-center text-sm rounded-md w-full px-2 py-2">
              <component
                :is="item.icon"
                :class="[
                        item.current
                        ? 'text-gray-300'
                        : 'text-gray-500 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                      ]"
                aria-hidden="true"/>
              {{ t(item.name) }}
            </a>
            <a
              v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT'"
              :href="item.href"
              target="_blank"
              class="w-full flex px-2 py-2 items-center"
            >
              <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
              <component
                v-else-if="item.name === 'Profix-Academy'"
                :is="item.icon"
                :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
              />
              {{ item.name }}
            </a>
          </div>
          <hr class="flex h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700 w-full">
          <template v-if="isAdmin">
            <span class="text-center text-sm font-light text-gray-500 my-2">ADMIN PANEL</span>
            <div
              v-for="item in getAdminNavigation"
              :key="item.name"
              :class="[
              $route.path === '/' + item.href ? 'bg-orange-500 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
              'group flex items-center  text-sm font-medium rounded-md',
              ]">
              <router-link
                :to=" '/' + item.href"
                class="w-full flex px-2 py-2 items-center"
                v-if="item.name !== 'cryptohouse' && item.name !== 'xstarter'"
                v-show="!item.children || children"
              >
                <component
                  :is="item.icon"
                  :class="[
                item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6'
                ]"
                  aria-hidden="true"/>
                {{ t(item.name) }}
              </router-link>
            </div>
          </template>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'

const { navigation, getAdminNavigation } = useStore().getters
const { t } = useI18n()
const children = ref(false)
const store = useStore()
const logo = computed(() => store.state.theme === 'dark' ? require('../assets/dark/logo-dark.png') : require('../assets/light/logo-light.png'))
const checkout2Lvl = () => {
  document.querySelectorAll('.checkoutClass').forEach((item) => item.classList.toggle('hidden'))
  children.value = !children.value
}
const isAdmin = computed(() => store.getters.getUserInfo.is_admin)
</script>
