<template>
  <div class="text-main">
    <h4 class="text-2xl text-center font-bold">{{ t('verificationCode') }}</h4>
    <p class="alert" v-if="error">{{ error }}</p>
    <my-input :title="t('enterCode')" type="number" v-model="code" />
    <div class="flex flex-col gap-2 md:flex-row justify-between mt-4">
      <button class="btn flex justify-center" @click="verify">
        {{ !isLoadVerify ? t('confirm') : '' }}
        <loader v-show="isLoadVerify"/>
      </button>
      <button class="btn bg-main-red flex justify-center" @click="resend">
        {{ !isLoadResend ? t('resendCode') : '' }}
        <loader v-show="isLoadResend"/>
      </button>
    </div>
    <notification :showNotification="showNotification" @close="showNotification = false">
      <p class="text-sm font-medium text-gray-900">{{ t('codeResend') }}: {{ store.state.userInfo.email }}</p>
    </notification>
  </div>
</template>

<script setup>
import MyInput from '@/components/UI/myInput.vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { url } from '@/main'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import Loader from '@/components/UI/loaderUI.vue'
import Notification from '@/components/UI/notification.vue'
import { useStore } from 'vuex'

const { t } = useI18n()
const route = useRoute()
const store = useStore()
const error = ref('')
const code = ref('')
const emit = defineEmits(['updTransaction', 'successful'])
const isLoadVerify = ref(false)
const isLoadResend = ref(false)
const showNotification = ref(false)
const verify = () => {
  error.value = ''
  isLoadVerify.value = true
  axios.post(`${url}/api/payments/withdraw/${route.params.id}/verify`, {
    code: code.value
  }, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then(() => {
      emit('successful')
      emit('updTransaction')
      isLoadVerify.value = false
    })
    .catch(() => {
      error.value = 'incorrect code'
      isLoadVerify.value = false
    })
}
const resend = () => {
  isLoadResend.value = true
  axios.post(`${url}/api/payments/withdraw/${route.params.id}/resend`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then(() => {
      emit('updTransaction')
      isLoadResend.value = false
      setTimeout(() => {
        showNotification.value = false
      }, 5000)
      showNotification.value = true
    })
    .catch(() => {
      error.value = 'incorrect code'
      isLoadResend.value = false
    })
}
</script>
