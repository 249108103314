<template>
  <div class="flex flex-col text-main">
    <div class="flex flex-wrap lg:flex-nowrap justify-between gap-4">
      <div class="flex items-center gap-4 mb-4 text-main">
        <p>{{ t('show') }}</p>
        <my-select :state="getOnShow" v-model="selectCount" class="w-12" styleBtn="!p-2 text-center" :hiddenIcon="true"/>
      </div>
      <slot></slot>
      <pagination-component
        v-if="nav"
        :pageNav="nav"
        @updTransactions="(data) => $emit('updTransactions', data)"
      />
    </div>
    <div class="grid p-0">
      <div
        :class="[
          'hidden 2sm:grid grid-cols-custom-3 grid-rows-1 p-2 py-3 dark:text-[#8d8d8d] text-[#6d849e] rounded-md gap-y-3',
          `2xl:grid-cols-custom-${ props.tableTitles.length }`,
          `xl:grid-cols-custom-${ props.tableTitles.length < 6 ? props.tableTitles.length : 6 }`,
          `lg:grid-cols-custom-${ props.tableTitles.length < 5 ? props.tableTitles.length : 5 }`,
          `sm:grid-cols-custom-${ props.tableTitles.length < 4 ? props.tableTitles.length : 4 }`,
          ]"
      >
      <button class="w-20"></button>
        <button
          v-for="(title, idx) in props.tableTitles"
          :key="title.name"
          :class="[
            'uppercase whitespace-nowrap text-sm md:text-md',
            idx === 0 ? 'text-left flex' : idx === 1 ? 'hidden sm:flex' : idx === 2 ? 'hidden lg:flex' : idx === 3 ? 'hidden xl:flex' : idx >= 4 ? 'hidden 2xl:flex' : '',
            idx + 1 === props.tableTitles.length ? 'justify-center sm:accent-inherit' : '',
            title.name === 'statusTransaction' ? 'hidden sm:!flex justify-center sm:accent-inherit' : '',
            title.name === 'dollarsTransaction' ? '!flex justify-end sm:justify-start sm:accent-inherit' : '',
            title.name === 'userLevel' ? '!hidden 2xl:!flex' : '',
            title.sort ? 'items-center gap-1' : ''
            ]"
        >
          {{ t(title.name) }}
          <!-- title.sort not usless here anywhere -->
        </button>
      </div>
      <table-cell v-for="(trans, idx) in props.transactions" :key="'cell' + idx" :trans="trans" :tableTitles="props.tableTitles" @deleteTransaction="(data) => $emit('updTransactions', data)"/>
      <p v-if="!props.transactions || props.transactions.length === 0" class="text-center rounded-md m-1 py-3 px-2 text-main">No data available in table</p>
    </div>
     <pagination-component
        v-if="nav"
        :pageNav="nav"
        @updTransactions="(data) => $emit('updTransactions', data)"
        class="self-end"
      />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { SwitchVerticalIcon } from '@heroicons/vue/solid'
import { useStore } from 'vuex';
import TableCell from '@/components/TableCell.vue';
import { useI18n } from 'vue-i18n';
import PaginationComponent from '@/components/UI/TableCells/paginationComponent.vue'
import MySelect from '@/components/UI/mySelect.vue'

const store = useStore()
const { t } = useI18n()
const emit = defineEmits(['updCount'])
const props = defineProps({
  tableTitles: Array,
  transactions: Array,
  nav: Array
})
const { getOnShow } = store.getters
const selectedCount = ref(store.getters.getSelectedPeople)
const selectCount = computed({
  get: () => selectedCount.value,
  set: (value) => {
    selectedCount.value = value
    emit('updCount', value.name)
  }
})
</script>
