export default {
  en: {
    loading: '...loading',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'submit',
    success: 'Successfully send!',
    successWait: 'Wait for administrator confirmation',
    successTransaction: 'Successfully',
    codeResend: 'Code was sent in the mail',
    buyHouse: 'buy',
  },
  ru: {
    loading: '...загрузка',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'Подтвердить',
    success: 'Успешно отправлено!',
    successWait: 'Ожидайте подтверждения администратора',
    successTransaction: 'Успешно!',
    codeResend: 'код был отправлен на почту',
    buyHouse: 'купить',
  },
  es: {
    loading: '...cargando',
    withdrawTransaction: 'Withdraw Transactions',
    submit: 'enviar',
    success: '¡Enviado con éxito!',
    successWait: 'Esperar la confirmación del administrador',
    successTransaction: 'Con éxito',
    codeResend: 'el código ha sido enviado a la oficina de correos',
    buyHouse: 'comprar',
  }
}
