<template>
  <div class="text-main">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-max w-full pt-5 pb-4 bg-white dark:bg-backgroundBlack">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 overflow-y-auto scroll">
              <nav class="flex-1 py-4 space-y-1">
                <div
                  v-for="item in navigation"
                  :key="item.name"
                  :class="[
                    $route.path === '/' + item.href ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
                  <router-link
                    v-if="!item.global"
                    :to=" '/' + item.href"
                    class="w-full flex px-2 py-2 items-center"
                    v-show="!item.children || children"
                    @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
                  >
                    <component
                      v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ t(item.name) }}
                  </router-link>
                  <a
                    v-if="item.name === 'cryptohouse'"
                    href="#"
                    @click.prevent="checkout2Lvl"
                    class="group flex items-center text-sm rounded-md w-full px-2 py-2">
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                        ? 'text-gray-300'
                        : 'text-gray-500 group-hover:text-white', 'flex-shrink-0 h-6 w-6'
                      ]"
                      aria-hidden="true"/>
                    {{ t(item.name) }}
                  </a>
                  <a
                    v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT'"
                    :href="item.href"
                    target="_blank"
                    class="w-full flex px-2 py-2 items-center"
                  >
                    <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
                    <component
                      v-else-if="item.name === 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ item.name }}
                  </a>
                </div>
                <hr class="flex h-0.5 bg-gray-300 text-gray-300 border-none dark:bg-gray-700 dark:text-gray-700 w-full">
                <template v-if="isAdmin">
                  <span class="text-center text-sm font-light text-gray-500 my-2">ADMIN PANEL</span>
                  <div
                    v-for="item in getAdminNavigation"
                    :key="item.name"
                    :class="[
              $route.path === '/' + item.href ? 'bg-orange-500 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
              'group flex items-center  text-sm font-medium rounded-md',
              ]">
                    <router-link
                      :to=" '/' + item.href"
                      class="w-full flex px-2 py-2 items-center"
                      v-if="item.name !== 'cryptohouse'"
                      v-show="!item.children || children"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6'
                ]"
                        aria-hidden="true"/>
                      {{ t(item.name) }}
                    </router-link>
                  </div>
                </template>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->

        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <!-- <side-bar></side-bar> -->
    <div class="app_main flex flex-col">
      <!-- Header -->
      <div class="sticky top-0 z-20 flex-shrink-0 flex h-20 bg-main-sky dark:bg-backgroundBlack drop-shadow-header dark:drop-shadow-none">
        <!-- <button type="button" class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button> -->
        <div class="relative flex-1 px-4 flex justify-between">
          <img :src="logo" alt="logo" class="h-5 w-auto self-center mr-auto 2sm:h-8">
          <div class="token_header flex mx-auto">
            <FIXTokenHeader/>
          </div>
          <div class="header_nav">
            <div
                  v-for="item in navigation"
                  class="header_nav__item"
                  :key="item.name"
                  :class="[
                    $route.path === '/' + item.href ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
                  <router-link
                    v-if="!item.global"
                    :to=" '/' + item.href"
                    class="w-full flex px-2 py-2 items-center"
                    v-show="!item.children || children"
                    @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
                  >
                    <component
                      v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ t(item.name) }}
                  </router-link>
                  <a
                    v-if="item.name === 'cryptohouse'"
                    href="#"
                    @click.prevent="checkout2Lvl"
                    class="group flex items-center text-sm rounded-md w-full px-2 py-2">
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                        ? 'text-gray-300'
                        : 'text-gray-500 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                      ]"
                      aria-hidden="true"/>
                    {{ t(item.name) }}
                  </a>
                  <a
                    v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT'"
                    :href="item.href"
                    target="_blank"
                    class="w-full flex px-2 py-2 items-center"
                  >
                    <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
                    <component
                      v-else-if="item.name === 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ item.name }}
                  </a>
                </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
              <the-language class="hidden md:block" />
              <user-navigation-skelet v-if="loading"/>
              <user-navigation v-show="!loading"/>
          </div>
        </div>
      </div>
      <!-- END Header -->
      <main class="flex-1">
        <div class="content_header flex justify-center">
          <FIXTokenHeader class="token_body flex flex-col 2sm:flex-row px-4 sm:px-6 md:px-8 pt-6 gap-3 justify-between" />
        </div>
        <my-office-skelet class="py-6" v-if="loading"/>
        <router-view class="py-6" v-show="!loading"></router-view>
      </main>
    </div>
    <div class="mobileNav">
      <div
                  v-for="item in navigation"
                  class="header_nav__item"
                  :key="item.name"
                  :class="[
                    $route.path === '/' + item.href ? 'bg-main-red text-white' : 'text-gray-500 dark:text-gray-300 hover:bg-main-red hover:text-white',
                    item.name === 'globalpool' ? '!text-green-400 font-bold hover:!text-white' : '',
                    item.name === 'cryptohouse' ? '!text-cyan-500 font-bold hover:!text-white' : '',
                    item.name === 'Profix-Academy' ? '!text-fuchsia-500 font-bold hover:!text-white' : '',
                    item.name === 'partners' ? 'border-b border-gray-300 dark:border-gray-700' : '',
                    item.name === 'FIX Global NFT' ? '!text-[#d3bb84] hover:!text-white font-bold border-b border-gray-300 dark:border-gray-700' : '',
                    // $route.path.split('/').includes(item.href) !== false ? 'bg-orange-500 text-white' : '',
                    'group flex items-center text-sm',
                    item.children ? 'ml-5' : ''
                  ]">
                  <router-link
                    v-if="!item.global"
                    :to=" '/' + item.href"
                    class="w-full flex px-2 py-2 items-center"
                    v-show="!item.children || children"
                    @click="$router.afterEach(() => {sidebarOpen = false; document.querySelector('body').innerHTML = '0px'})"
                  >
                    <component
                      v-if="item.name !== 'FIX Global NFT' || item.name !== 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ t(item.name) }}
                  </router-link>
                  <a
                    v-if="item.name === 'cryptohouse'"
                    href="#"
                    @click.prevent="checkout2Lvl"
                    class="group flex items-center text-sm rounded-md w-full px-2 py-2">
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                        ? 'text-gray-300'
                        : 'text-gray-500 group-hover:text-white', 'mr-3 flex-shrink-0 h-6 w-6'
                      ]"
                      aria-hidden="true"/>
                    {{ t(item.name) }}
                  </a>
                  <a
                    v-if="item.name === 'Profix-Academy' || item.name === 'FIX Global NFT'"
                    :href="item.href"
                    target="_blank"
                    class="w-full flex px-2 py-2 items-center"
                  >
                    <img :src="require('@/assets/icons/globalNFT.png')" alt="Global NFT" v-if="item.name === 'FIX Global NFT'" class="mr-3 flex-shrink-0 h-6 w-6">
                    <component
                      v-else-if="item.name === 'Profix-Academy'"
                      :is="item.icon"
                      :className="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                      :class="[$route.path === '/' + item.href ? '!fill-white' : 'group-hover:!fill-white', 'mr-3 flex-shrink-0 h-6 w-6']"
                    />
                    {{ item.name }}
                  </a>
                </div>
    </div>
<!--    <ModalComponent :title="t('important')" :isOpen="notificationWallets" @openModal="notificationWallets = true" @closeModal="notificationWallets = false">-->
<!--      <modalAlertWallets @closeModal="notificationWallets = false" />-->
<!--    </ModalComponent>-->
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount, onMounted,
  ref
} from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'
import SideBar from '@/components/SideBar.vue'
import { useI18n } from 'vue-i18n';
import TheLanguage from '@/components/TheLanguage.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import UserNavigation from '@/components/UserNavigation.vue';
import FIXTokenHeader from '@/components/FIXTokenHeader.vue';
import MyOfficeSkelet from '@/components/skeletons/myOfficeSkelet.vue'
import UserNavigationSkelet from '@/components/skeletons/userNavigationSkelet.vue'
import GlobalNftIcon from '@/components/icons/GlobalNftIcon.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import modalAlertWallets from './components/UI/modalAlertWallets.vue'

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    SideBar,
    TheLanguage,
    UserNavigation,
    FIXTokenHeader,
    MyOfficeSkelet,
    UserNavigationSkelet,
    GlobalNftIcon,
    ModalComponent,
    modalAlertWallets
  },
  setup() {
    const store = useStore()
    const { navigation, userNavigation, getAdminNavigation } = store.getters
    const sidebarOpen = ref(false)
    const loading = ref(false)
    const { t } = useI18n()
    const children = ref(false)
    const notificationWallets = ref(false)
    const router = useRouter()
    const checkout2Lvl = () => {
      document.querySelectorAll('.checkoutClass').forEach((item) => item.classList.toggle('hidden'))
      children.value = !children.value
    }
    const logo = computed(() => store.state.theme === 'dark' ? require('./assets/dark/logo-dark.png') : require('./assets/light/logo-light.png'))
    const isAdmin = computed(() => store.getters.getUserInfo.is_admin === 1)
    const fetchData = () => {
      loading.value = true
      store.dispatch('fetchUserInfo').then(() => {
        store.dispatch('fetchStats').then(() => {
          store.dispatch('getPrice').then(() => {
            loading.value = false
          })
        })
      })
    }
    onBeforeMount(() => {
      if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'en')
      }
      store.commit('getCountries')
      store.commit('changeTheme')

      if (localStorage.getItem('verify')) {
        const link = new URL(localStorage.getItem('verify'))
        router.push(link.pathname + link.search)
        localStorage.removeItem('verify')
      }
    })

    return {
      sidebarOpen,
      userNavigation,
      navigation,
      t,
      children,
      checkout2Lvl,
      isAdmin,
      getAdminNavigation,
      fetchData,
      loading,
      logo,
      notificationWallets
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

.app_main{
  max-width: 1350px;
  margin: 0 auto;
}

.header_nav{
  display: flex;
}

.header_nav__item a{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_nav__item{
  padding-left: 10px;
  padding-right: 10px;
}

.mobileNav{
  z-index: 1001;
  position: fixed;
  bottom: 0;
  display: none;
  justify-content: space-around;
  width: 100%;
  background: #fff;
}

@media (max-width: 1150px) {

.header_nav{
  display: none;
}

.mobileNav{
  display: flex;
}

}

.token_header{
  display: none;
}

@media (min-width: 570px) {
  .token_body{
    display: none;
  }

  .token_header{
    display: flex;
  }
}

@media (max-width: 550px) {
  .mobileNav a {
    font-size: 10px;
  }
}

</style>
