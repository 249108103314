<template>
  <div class="flex flex-col">
    <router-link :to="linkToTransaction" class=" text-orange-600">
      {{ t('viewTransaction') }}
    </router-link>
    <button v-if="status === 0" class="bloc sm:absolute right-2">
      <XIcon class="w-6 h-6 text-red-600" @click="deleteTransaction(types[type])"/>
    </button>
  </div>
</template>

<script setup>
import {
  XIcon,
} from '@heroicons/vue/outline'
import axios from 'axios'
import { url } from '@/main'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  value: Number,
  status: Number,
  id: Number,
  type: Number
})
const emit = defineEmits(['deleteTransaction'])
const types = ref({
  0: 'deposit',
  1: 'withdraw',
  2: 'FIXTokenCheck',
  11: 'deposit',
  3: 'deposit',
  4: 'deposit',
  5: 'deposit',
  6: 'deposit',
  7: 'deposit',
  8: 'deposit',
  9: 'withdraw',
  10: 'withdraw',
  12: 'exchange',
  13: 'withdraw',
  14: 'exchange',
  15: 'withdraw',
  16: 'exchange',
})
const deleteTransaction = (type) => {
  axios.delete(`${url}/api/payments/${type}/${props.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true }).then(() => {})
    .then(() => {
      emit('deleteTransaction')
    })
}
const linkToTransaction = ref(`/${types.value[props.type]}/${props.id}`)
</script>
