<template>
  <div class="flex flex-col items-start">
    <h4 class="text-left text-sm sm:text-base">{{ t(typeName[props.type]) }} {{ props.type === 11 ? 'FIX GOLD' : '' }} </h4>
    <small class="dark:text-[#8d8d8d]">{{ props.date ? props.date.split(' ')[0].split('-').reverse().join('.') : '-' }}</small>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  type: Number,
  date: String
})
const { t } = useI18n()
const typeName = ref({
  11: 'depositTransaction',
  0: 'depositTransaction',
  1: 'withdrawType',
  2: 'exchangeType',
  3: 'directBonusType',
  4: 'globalPoolGoldType',
  5: 'globalPoolDiamondType',
  6: 'weeklyBonusType',
  7: 'depositFixBonusType',
  8: 'depositUsdBonusType',
  9: 'withdrawFixBonusType',
  10: 'withdrawUsdBonusType',
  12: 'autoExchangeType',
  13: 'withdrawFixGoldType',
  14: 'exchangeFixGoldType',
  15: 'depositCryptohouseType',
  16: 'exchangeFixOneType',
})
</script>
