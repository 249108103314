<template>
  <transition-group name="skeleton" mode="out-in">
  <div class="flex items-center ml-3 rounded-full w-40">
    <div class="flex items-end gap-2 flex-col mx-2 w-full">
      <div class="skeleton skeleton--wave w-full h-3.5 rounded-md bg-gray-700" />
      <div class="skeleton skeleton--wave w-full h-2.5 rounded-md bg-gray-700 w-1/2" />
    </div>
    <div class="skeleton skeleton--wave w-16 h-10 !rounded-full bg-gray-700"></div>
  </div>
  </transition-group>
</template>

<script setup>

</script>
