<template>
  <div class="items-center gap-x-3 flex">
    <a class="flex items-center w-full 2sm:w-52 gap-x-2 bg-white dark:bg-gradient-to-r dark:from-[#292929] dark:to-[#171717] p-3 rounded-md" href="#">
      <img class="token_img" :src="require('@/assets/icons/insticon.png')" alt="">
      <div class="flex flex-col justify-center grow">
        <strong class="text-main-textSky dark:text-white text-xs hover:text-gray-400">INSTANT  </strong>
        <span class="text-main-textSkyLight text-gray-600 text-xxs">INSTANT Token</span>
      </div>
      <div class="flex flex-col justify-center items-end">
        <strong class="text-main-textSky dark:text-white text-xs">{{ 1 ?? t('loading') }}</strong>
        <strong class="text-green-600 text-xxs flex items-center gap-x-1">
          <BIconCaretUpFill/>
          +{{ '1.0' }}%
        </strong>
      </div>
    </a>
  </div>

</template>

<script setup>
import { BIconCaretUpFill } from 'bootstrap-icons-vue'
import { useI18n } from 'vue-i18n'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import FixGoldTokenIcon from '@/components/icons/FixGoldTokenIcon.vue'
import FixOneTokenIcon from '@/components/icons/FixOneTokenIcon.vue'

const { t } = useI18n()
const store = useStore()
const tokenOne = computed(() => store.getters.getFixOneChangeRate)
const tokenGold = computed(() => store.getters.getFixGoldChangeRate)
onBeforeMount(() => {
  store.dispatch('getPrice')
})
</script>

<style scoped>

.token_img{
  width: 20px;
  height: 20px;
}

.token_body{
  width: 100%;
}

.token_body .items-center{
  width: 100%;
}
</style>
