<template>
  <div class="w-full px-4 sm:px-6 md:px-8">
    <div class="card-bg" v-if="status === 0">
      <h4 class="text-lg">{{ t('submitWithdraws') }}</h4>
      <p>{{ t('submitInstruction') }}</p>
      <my-input :title="t('hashTransaction')" class="mt-4" v-model="hash"/>
      <label for="uploadScreenshot" class="flex justify-between gap-1 border rounded-md cursor-pointer mt-4">
        <span class="p-1 truncate">{{ screenshot }}</span>
        <span class="border-l p-1 bg-gray-800 rounded-md">{{ t('screenshotTransaction') }}</span>
        <input type="file" id="uploadScreenshot" name="uploadScreenshot" class="hidden" @change="update">
      </label>
      <button class="btn px-6 mt-4" @click="confirmPayment">{{ t('submit') }}</button>
    </div>
    <div class="card-bg mt-4">
      <transactions-table :table-titles="getTableTransactionsTitlesDefault.slice(0, -1)" :transactions="transaction"/>
    </div>
    <notification :showNotification="showNotification" @close="showNotification = false">
      <p class="text-sm font-medium text-gray-900">{{ t('success') }}</p>
      <p class="mt-1 text-sm text-gray-500">{{ t('successWait') }}</p>
    </notification>
  </div>
</template>

<script setup>
import MyInput from '@/components/UI/myInput.vue'
import { ref, onBeforeMount } from 'vue'
import axios from 'axios'
import { url } from '@/main'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import TransactionsTable from '@/components/transactionsTable.vue'
import Notification from '@/components/UI/notification.vue'
import { useStore } from 'vuex'

const hash = ref('')
const screenshot = ref('')
const showNotification = ref(false)
const transaction = ref([])
const status = ref('')
const { t } = useI18n()
// const emit = defineEmits(['updTransaction'])
const route = useRoute()
const store = useStore()
const { getTableTransactionsTitlesDefault } = store.getters
const update = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  screenshot.value = idScreenshot.name
}
const fetchTransaction = () => {
  axios.get(`${url}/api/payments/transaction/${route.params.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then((response) => {
      transaction.value = [
        {
          type: response.data.data.type,
          dollars: response.data.data.amount_in_usd,
          token: Number(response.data.data.amount_in_fix).toFixed(4),
          data: response.data.data.created_at.split('.')[0].split('T').join(' '),
          status: response.data.data.status,
        }
      ]
      status.value = response.data.data.status
      if (response.data.data.status === 10) {
        setTimeout(() => {
          fetchTransaction()
        }, 30000)
      }
    })
}
const confirmPayment = () => {
  const idScreenshot = document.querySelector('#uploadScreenshot').files[0]
  const formData = new FormData()

  if (hash.value) formData.append('tx_id', hash.value)

  if (screenshot.value) formData.append('photo', idScreenshot)

  axios.post(`${url}/api/payments/exchange/${route.params.id}`, formData, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then(() => {
      showNotification.value = true
      fetchTransaction()
    })
}
onBeforeMount(() => {
  fetchTransaction()
})
</script>
