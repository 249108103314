import { createStore } from 'vuex';
import language from '@/store/modules/language';
import routes from '@/store/modules/routes';
import myOffice from '@/store/modules/myOffice';
import prices from '@/store/modules/prices';
import user from '@/store/modules/user';
import transactions from '@/store/modules/transactions';
import axios from 'axios';
import { url } from '@/main';

export default createStore({
  modules: {
    language,
    routes,
    myOffice,
    prices,
    user,
    transactions
  },
  state: {
    theme: 'light',
    screenWidth: window.innerWidth,
    stateQuestions: {
      registrationVerification: [
        { title: 'group0Title0', description: 'group0description0' },
        { title: 'group0Title1', description: 'group0description1' },
        { title: 'group0Title2', description: 'group0description2' },
        { title: 'group0Title3', description: 'group0description3' },
      ],
      depositWithdrawal: [
        { title: 'group1Title0', description: 'group1description0' },
        { title: 'group1Title1', description: 'group1description1' },
        { title: 'group1Title2', description: 'group1description2' },
        { title: 'group1Title3', description: 'group1description3' },
        { title: 'group1Title4', description: 'group1description4' },
        { title: 'group1Title5', description: 'group1description5' },
        { title: 'group1Title6', description: 'group1description6' },
        { title: 'group1Title7', description: 'group1description7' },
      ],
      bonusesMarketing: [
        { title: 'group2Title0', description: 'group2description0' },
        { title: 'group2Title1', description: 'group2description1' },
        { title: 'group2Title2', description: 'group2description2' },
        { title: 'group2Title3', description: 'group2description3' },
        { title: 'group2Title4', description: 'group2description4' },
      ],
      tokenGrowthIncome: [
        { title: 'group3Title0', description: 'group3description0' },
        { title: 'group3Title1', description: 'group3description1' },
        { title: 'group3Title2', description: 'group3description2' },
      ],
      aboutCompany: [
        { title: 'group4Title0', description: 'group4description0' },
        { title: 'group4Title1', description: 'group4description1' },
        { title: 'group4Title2', description: 'group4description2' },
      ]
    },
    transactionsTableDefault: [
      { name: 'typeTransaction', sort: false },
      { name: 'dollarsTransaction', sort: true },
      { name: 'FIXTransaction', sort: true },
      { name: 'statusTransaction', sort: true },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsTablePartnership: [
      { name: 'typeTransaction', sort: false },
      { name: 'partnerName', sort: false },
      { name: 'userLevel', sort: false },
      { name: 'dollarsTransaction', sort: true },
      { name: 'FIXTransaction', sort: true },
      { name: 'statusTransaction', sort: true },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsPartner: [
      { name: 'id', sort: false },
      { name: 'partnerName', sort: false },
      { name: 'userLevel', sort: false },
      { name: 'FIXWallet', sort: false },
      { name: 'email', sort: false },
      { name: 'telegram', sort: false },
    ],
    transactionsUsersManager: [
      { name: 'name', sort: false },
      { name: 'email', sort: false },
      { name: 'Fix Bonus Account', sort: false },
      { name: 'FIXTransaction', sort: false },
      { name: 'partner account', sort: false },
      { name: 'admin', sort: false },
      { name: 'sponsor', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionVerifications: [
      { name: 'name', sort: false },
      { name: 'email', sort: false },
      { name: 'user', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsAdminDeposits: [
      { name: 'id', sort: false },
      { name: 'name', sort: false },
      { name: 'total', sort: false },
      { name: 'user', sort: false },
      { name: 'created at', sort: false },
      { name: 'last update', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsAdminGlobalPool: [
      { name: 'id', sort: false },
      { name: 'name', sort: false },
      { name: 'GlobalPool status', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsAdminWithdraw: [
      { name: 'name', sort: false },
      { name: 'USDT Wallet', sort: false },
      { name: 'total', sort: false },
      { name: 'USD', sort: false },
      { name: 'fix', sort: false },
      { name: 'fee', sort: false },
      { name: 'user', sort: false },
      { name: 'created at', sort: false },
      { name: 'lat update', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsAdminPromotion: [
      { name: 'id', sort: false },
      { name: 'name', sort: false },
      { name: 'actionsTransaction', sort: false },
    ],
    transactionsAdmin: [
      { name: 'type', sort: false },
      { name: 'dollarsTransaction', sort: true },
      { name: 'FIXTransaction', sort: true },
      { name: 'user', sort: false },
      { name: 'dataTransaction', sort: true },
      { name: 'statusTransaction', sort: true },
    ],
    countries: []
  },
  getters: {
    getQuestions(state) {
      return state.stateQuestions
    },
    getTableTransactionsTitlesDefault(state) {
      return state.transactionsTableDefault
    },
    getTransactionsTablePartnership(state) {
      return state.transactionsTablePartnership
    },
    getTransactionsPartner(state) {
      return state.transactionsPartner
    },
    getTransactionsUsersManager(state) {
      return state.transactionsUsersManager
    },
    getTransactionVerifications(state) {
      return state.transactionVerifications
    },
    getTransactionsAdminDeposits(state) {
      return state.transactionsAdminDeposits
    },
    getTransactionsAdminGlobalPool(state) {
      return state.transactionsAdminGlobalPool
    },
    getTransactionsAdminWithdraw(state) {
      return state.transactionsAdminWithdraw
    },
    getTransactionsAdminPromotion(state) {
      return state.transactionsAdminPromotion
    },
    getTransactionsAdmin(state) {
      return state.transactionsAdmin
    },
    getCsrfToken() {
      let CSRF
      document.cookie.split(';').forEach((el) => {
        // eslint-disable-next-line
        if (el.match(/XSRF-TOKEN/i)) CSRF = el.split('=')[1]
      })
      return CSRF
    },
    getFixOneInUSD(state) {
      return (state.userInfo.fix_balance + state.userInfo.fix_internal_balance + state.userInfo.fix_bonus_balance) * state.prices.fixOneChangeRate.price ?? 0
    },
    getFixGoldInUSD(state) {
      return (state.userInfo.fix_gold_balance + state.userInfo.fix_gold_internal_balance + state.userInfo.fix_gold_bonus_balance) * state.prices.fixGoldChangeRate.price ?? 0
    },
    getStateCountries(state) {
      return state.countries
    },
    balanceState(state) {
      return state.myOffice.balance
    },
  },
  mutations: {
    getCountries(state) {
      axios.get(`${url}/api/common/countries`).then((response) => {
        state.countries = response.data
      })
    },
    changeTheme(state) {
      // if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
      //   document.documentElement.classList.add('dark')
      //   state.theme = 'dark'
      //   localStorage.theme = 'dark'
      // } else {
      //   document.documentElement.classList.remove('dark')
      //   state.theme = 'light'
      //   localStorage.theme = 'light'
      // }
    }
  },
  actions: {
    async firstFetchLoad({ dispatch }) {
      await dispatch('fetchStats')
      await dispatch('getPrice')
      await dispatch('fetchUserInfo')
    }
  }
});
