export default {
  en: {
    general: 'General',
    changePassword: 'Change Password',
    wallets: 'Wallet Adress',
    warningImg: 'Allowed JPG, GIF or PNG. Max size of 5Mb',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    retypeNewPassword: 'Retype New Password',
    saveChanges: 'Save Changes',
    saveWallets: 'Attention! Do not save your Address from different Bourses. Only your personal address that supports ERC20 and BSC (BEP20) tokens. Trust Address , MetaMask etc.',
    wallet: 'Address',
    instruction: 'Instruction',
    addWallet: 'add address',
    uploadImage: 'upload image',
    reset: 'Reset',
  },
  ru: {
    general: 'Основное',
    changePassword: 'Изменить пароль',
    wallets: 'Wallet Adress',
    warningImg: 'Разрешены фото формата JPG, GIF или PNG. Максимальный размер 5Mb',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый Пароль',
    retypeNewPassword: 'Повторите новый пароль',
    saveChanges: 'Сохранить',
    saveWallets: 'Внимание!!! Не сохраняйте свои адреса с различных БИРЖ. Только ваш личный адрес который поддерживает токены ERC20 и BSC (BEP20). Trust Address , MetaMask etc.',
    wallet: 'Адрес',
    instruction: 'Инструкция',
    addWallet: 'Добавьте адрес',
    uploadImage: 'загрузить изображение',
    reset: 'Сбросить',
  },
  es: {
    general: 'General',
    changePassword: 'Cambiar la Contraseña',
    wallets: 'FIX Adress',
    warningImg: 'JPG, GIF o PNG permitidos. Tamaño máximo de 5Mb',
    oldPassword: 'Contraseña Anterior',
    newPassword: 'NuevaContraseña',
    retypeNewPassword: 'Reescriba Nueva Contraseña',
    saveChanges: 'Guardar Cambios',
    saveWallets: 'Atención!!! No guardes tus carteras de diferentes BASEs. Sólo su cartera personal que soporta ERC20 y tokens BSC (BEP20). Trust Address, MetaMask, etc.',
    wallet: 'Dirección',
    instruction: 'Instrucciones',
    addWallet: 'Agregar Dirección',
    uploadImage: 'subir imagen',
    reset: 'Restablecer',
  }
}
