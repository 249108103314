export default {
  en: {
    registrationVerification: 'Registration / Verification',
    depositWithdrawal: 'Deposit / Withdrawal',
    bonusesMarketing: 'Bonuses / Marketing',
    tokenGrowthIncome: 'FIX Token Growth / Income',
    aboutCompany: 'About company',
    group0Title0: 'How do I sign up for an investor account?',
    group0Title1: 'How to pass the verification?',
    group0Title2: 'Is it possible to register multiple accounts to one email and one person?',
    group0Title3: 'Is registration possible for U.S. citizens?',
    group0description0: 'You can register an investors account by clicking on the "Open account" button on the main page and then selecting "Open account" in the investors tab or by following the referral link sent to you by the partner.',
    group0description1: 'Fill in the "Personal Profile" correctly. Important, the entered name, date of birth and registration address must fully correspond to the data in the attached document. After that, fill in the profile to the end and click on the button "Send to verification". In order to properly pass the verification you need upload Identity document (passport, foreign passport, driving license, ID-card) and a document confirming the address of your registration (page with the registration from your passport, bank statement, utility bill). ',
    group0description2: 'Only one account can be attached to one email. Also, you can only register one account per person.',
    group0description3: 'Yes, it is possible.',
    group1Title0: 'How to make a deposit?',
    group1Title1: 'Are there any limits on deposits?',
    group1Title2: 'What is the deposit fee?',
    group1Title3: 'What is the deadline for a deposit?',
    group1Title4: 'How to withdraw money?',
    group1Title5: 'Are there any limits on the withdrawal?',
    group1Title6: 'What\'s the fee for withdrawal?',
    group1Title7: 'What are the deadlines for withdrawal?',
    group1description0: 'You can make a deposit in your personal area, going to the tab "Deposit to account". Deposit is made in USDT (Tether - ERC20, Tron - TRC20), by transferring funds to an address specified by us. ',
    group1description1: 'The minimum deposit amount is from 3.000 USDT. <br>The maximum deposit amount is 10.000.000 USDT.',
    group1description2: '1% deposit fee plus up to 1% growth fee, depends on what day of the week you invested and how many days left to the growth of token FIX',
    group1description3: 'Deposit is made to a maximum of 24 hours.',
    group1description4: 'In order to withdraw funds, go to your personal area and click on the tab "Withdrawal". Withdrawal is made by sending USDT (Tether, Tron) to your wallet.',
    group1description5: 'The minimum withdrawal amount is 50 USDT. The maximum withdrawal amount is 1,000,000 USDT per transaction.',
    group1description6: 'There is no withdrawal fee from the Сompany, you only pay the blockchain network fee for the transfer.',
    group1description7: 'Request for withdrawal is processed within 24 hours.',
    group2Title0: 'What bonus can I get for inviting an investor? ',
    group2Title1: 'What is the Leaders Pool?',
    group2Title2: 'How do I get into the 3% and 2% pool?',
    group2Title3: 'What other bonuses are there?',
    group2Title4: 'When are bonuses earned on an investor\'s income?',
    group2description0: 'For inviting a new investor you receive a one-time bonus of 3% of the invested amount. And then 10% of the invited investor\'s income (provided that your FIX token account is at least $1,000).',
    group2description1: 'This is a bonus, which is 5% of the fund\'s total income and is paid monthly. It is split into 2 categories - 3% and 2%.',
    group2description2: 'To enter the pool at 3% the following conditions must be met: make a turnover with the team in five levels of 800 000 USDT. Personal investment must be at least 5 000 USDT. To enter the pool for another 2% must meet the following conditions: make a turnover of 1 000 000 USDT with the team in five levels. Personal investment must be at least 10 000 USDT.',
    group2description3: `Bonuses on investor's income (Weekly). <br>
        1 line - 10% of the investor's income (with personal investment of at least 1 000 USDT). <br>
        2 line - 5% of the investor's income (with a personal investment of at least 2 500 USDT). <br>
        3 line - 3% of the investor's income (on a personal investment of at least 5,000 USDT). <br>
        4th line - 2% of the investor's income (on a personal investment of at least 7,500 USDT). <br>
        Line 5 - 1% of the investor's income (with a personal investment of at least 10,000 USDT).`,
    group2description4: 'A weekly bonus from investor\'s income is accrued every Sunday.',
    group3Title0: 'What is the growth of the FIX token secured?',
    group3Title1: 'When does the price of a FIX token rise?',
    group3Title2: 'Why is the FIX token only growing 1% a week?',
    group3description0: 'The fixed growth of the FIX token is due to the growth of the INSTANT CASH Capital investment fund\'s total capital through trading in the currency, stock and crypto markets.',
    group3description1: 'The price of the FIX token grows by 1% every Saturday.',
    group3description2: 'Smart contract provides weekly token growth of 1%.',
    group4Title0: 'What is INSTANT CASH Capital? ',
    group4Title1: 'What is the company doing?',
    group4Title2: 'Where is INSTANT CASH Capital registered?',
    group4description0: 'INSTANT CASH Capital - Quantum Investment CryptoFund.',
    group4description1: 'Provides trading operations on currency, stock and crypto markets.',
    group4description2: ' INSTANT CASH Capital LLC. is registered under registration number 834 LLC 2021 in the jurisdiction of St. Vincent and the Grenadines and its registered office is Suite 305, Griffith Corporate Centre, P.O.Box 1510, Beachmont, Kingstown, St. Vincent and the Grenadines.',
    stillQuestionTitle: 'You still have a question?',
    stillQuestionDescription: 'If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!',
    telegramManagerTitle: 'INSTANT CASH Manager in Telegram',
    telegramManagerDescription: 'We are always happy to help',
    emailManagerDescription: 'Best way to get answer faster!',
  },
  ru: {
    registrationVerification: 'Регистрация / Верификация',
    depositWithdrawal: 'Депозит / Вывод средств',
    bonusesMarketing: 'Бонусы / Маркетинг',
    tokenGrowthIncome: 'FIX Token Рост / Доход',
    aboutCompany: 'О компании',
    group0Title0: 'Как зарегистрировать счет инвестора?',
    group0Title1: 'Как пройти проверку?',
    group0Title2: 'Можно ли зарегистрировать несколько учетных записей на одной электронной почте и одному человеку?',
    group0Title3: 'Возможна ли регистрация для граждан США?',
    group0description0: 'Вы можете зарегистрировать счет инвестора, нажав на кнопку "Открыть счет" на главной странице, а затем выбрав "Открыть счет" во вкладке "Инвесторы" или же перейдя по реферальной ссылке, присланной вам партнером.',
    group0description1: 'Правильно заполните "Личный профиль". Важно, введенные имя, дата рождения и адрес регистрации должны полностью соответствовать данным в прилагаемом документе. После этого заполните профиль до конца и нажмите на кнопку "Отправить на проверку". <br> Для того чтобы правильно пройти проверку, необходимо необходимо загрузить документ, удостоверяющий личность (паспорт, загранпаспорт, водительское удостоверение, ID-карту) и документ, подтверждающий адрес Вашей регистрации (страница с пропиской из паспорта, выписка из банка, счет за коммунальные услуги).',
    group0description2: 'К одному электронному адресу может быть привязан только один аккаунт. Кроме того, можно зарегистрировать только одну учетную запись на одного человека. ',
    group0description3: 'Да, это возможно.',
    group1Title0: 'Как сделать депозит?',
    group1Title1: 'Существуют ли какие-либо ограничения по вкладам?',
    group1Title2: 'Какова комиссия за депозит?',
    group1Title3: 'Каков крайний срок внесения депозита?',
    group1Title4: 'Как снять деньги?',
    group1Title5: 'Есть ли ограничения на вывод средств?',
    group1Title6: 'Какая комиссия за вывод средств?',
    group1Title7: 'Каковы сроки вывода средств?',
    group1description0: 'Вы можете пополнить счет в личном кабинете, перейдя на вкладку "Пополнить счет". Депозит осуществляется в USDT (Tether - ERC20, Tron - TRC20), путем перевода средств на указанный нами адрес.',
    group1description1: 'Минимальная сумма депозита составляет от 3.000 USDT. <br>Максимальная сумма депозита составляет 10.000.000 USDT.',
    group1description2: '1% комиссия за депозит плюс до 1% комиссия за рост, зависит от того, в какой день недели вы инвестировали и сколько дней осталось до роста токена FIX',
    group1description3: 'Депозит вносится на срок не более 24 часов.',
    group1description4: 'Для того чтобы вывести средства, зайдите в свой личный кабинет и нажмите на вкладку "Вывод средств". Вывод средств осуществляется путем отправки USDT (Tether, Tron) на ваш кошелек.',
    group1description5: 'Минимальная сумма вывода составляет 50 USDT. Максимальная сумма вывода - 1 000 000 USDT за одну транзакцию.',
    group1description6: 'Комиссия за вывод средств с компании отсутствует, вы платите только комиссии сети блокчейн за перевод.',
    group1description7: 'Запрос на вывод средств обрабатывается в течение 24 часов.',
    group2Title0: 'Какой бонус я могу получить за приглашение инвестора?',
    group2Title1: 'Что такое пул лидеров?',
    group2Title2: 'Как попасть в пул 3% и 2%?',
    group2Title3: 'Какие еще есть бонусы?',
    group2Title4: 'Когда начисляются бонусы на доход инвестора?',
    group2description0: 'За приглашение нового инвестора вы получаете единовременный бонус в размере 3% от инвестируемой суммы. А затем 10% от дохода приглашенного инвестора (при условии, что ваш FIX токен-счет составляет не менее $1 000).',
    group2description1: 'Это бонус, который составляет 5% от общего дохода фонда и выплачивается ежемесячно. Он делится на 2 категории - 3% и 2%',
    group2description2: 'Чтобы попасть в пул 3%, необходимо выполнить следующие условия: сделать оборот с командой в пять уровней на сумму 800 000 USDT. Личные инвестиции должны быть не менее не менее 5 000 USDT. <br> Чтобы войти в пул еще на 2%, необходимо выполнить следующие условия: сделать оборот в 1 000 000 USDT с командой в пяти уровнях. Личные инвестиции должны быть не менее 10 000 USDT.',
    group2description3: `Бонусы на доход инвестора (еженедельно). <br>
        1 линия - 10% от дохода инвестора (при личной инвестиции не менее 1 000 USDT). <br>
        2 строка - 5% от дохода инвестора (с личными инвестициями не менее 2 500 USDT). <br>
        3 строка - 3% от дохода инвестора (при личной инвестиции не менее 5 000 USDT). <br>
        4-я строка - 2% от дохода инвестора (при личной инвестиции не менее 7 500 USDT). <br>
        5-я строка - 1% от дохода инвестора (при личной инвестиции не менее 10 000 USDT).`,
    group2description4: 'Еженедельный бонус от дохода инвестора начисляется каждое воскресенье.',
    group3Title0: 'Чем обеспечен рост токена FIX?',
    group3Title1: 'Когда происходит рост цены токена FIX?',
    group3Title2: 'Почему цена токена FIX растет только на 1% в неделю?',
    group3description0: 'Фиксированный рост токена FIX обусловлен ростом капитала INSTANT CASH Capital общего капитала инвестиционного фонда за счет торговли на валютном, фондовом и крипто-рынках.',
    group3description1: 'Цена токена FIX растет на 1% каждую субботу.',
    group3description2: 'Умный контракт обеспечивает еженедельный рост токена на 1%.',
    group4Title0: 'Что такое INSTANT CASH Capital?',
    group4Title1: 'Чем занимается компания?',
    group4Title2: 'Где зарегистрирован INSTANT CASH Capital?',
    group4description0: 'INSTANT CASH Capital - криптофонд квантовых инвестиций.',
    group4description1: 'Проводит торговые операции на валютном, фондовом и крипто-рынках.',
    group4description2: 'INSTANT CASH Capital LLC. зарегистрирована под регистрационным номером 834 LLC 2021 в юрисдикции Сент-Винсент и Гренадины и ее зарегистрированным офисом является Suite 305, Griffith Corporate Centre, P.O.Box 1510, Beachmont, Kingstown, St. Vincent and the Grenadines.',
    stillQuestionTitle: 'До сих пор есть вопросы?',
    stillQuestionDescription: 'Если вы не можете найти вопрос в нашем FAQ, вы всегда можете связаться с нами. Мы ответим вам в ближайшее время!',
    telegramManagerTitle: 'INSTANT CASH Менеджер в Telegram',
    telegramManagerDescription: 'Мы всегда рады помочь',
    emailManagerDescription: 'Лучший способ получать ответ быстрее!',
  },
  es: {
    registrationVerification: 'Registro / Verificación',
    depositWithdrawal: 'Depósito / Retiro',
    bonusesMarketing: 'Bonificaciones / Marketing',
    tokenGrowthIncome: 'FIX Token Crecimiento / Ingresos',
    aboutCompany: 'Acerca de la compañía',
    group0Title0: 'Cómo me registro para obtener una cuenta de inversor?',
    group0Title1: 'Cómo pasar la verificación?',
    group0Title2: 'Es posible registrar varias cuentas para un correo electrónico y una persona?',
    group0Title3: 'Es posible el registro para ciudadanos estadounidenses?',
    group0description0: 'Puede registrar una cuenta de inversores haciendo clic en el botón "Abrir cuenta" en la página principal y luego seleccionando "Abrir cuenta" en la pestaña de inversores o por siguiendo el enlace de referencia que le envió el socio.',
    group0description1: 'Complete el "Perfil personal" correctamente. Importante, el nombre ingresado, la fecha de La dirección de nacimiento y registro debe corresponder plenamente a los datos del anexo. documento. Después de eso, complete el perfil hasta el final y haga clic en el botón "Enviar a verificación". <br> Para aprobar correctamente la verificación, necesita cargar documento de identidad (pasaporte, pasaporte extranjero, licencia de conducir, DNI) y un documento que confirme la dirección de su registro (página con el registro de su pasaporte, extracto bancario, factura de servicios públicos).',
    group0description2: 'Solo se puede adjuntar una cuenta a un correo electrónico. Además, solo puede registrar una cuenta por persona.',
    group0description3: 'Sí, es posible.',
    group1Title0: 'Cómo realizar un depósito?',
    group1Title1: 'Hay límites para los depósitos?',
    group1Title2: 'Cuál es la tarifa de depósito?',
    group1Title3: 'Cuál es la fecha límite para un depósito?',
    group1Title4: 'Cómo retirar dinero?',
    group1Title5: 'Hay algún límite en el retiro?',
    group1Title6: 'Cuál es la tarifa por retiro?',
    group1Title7: 'Cuáles son los plazos para el retiro?',
    group1description0: 'Puedes realizar un depósito en tu área personal, yendo a la pestaña "Depositar a cuenta ". El depósito se realiza en USDT (Tether - ERC20, Tron - TRC20), por transferir fondos a una dirección especificada por nosotros.',
    group1description1: 'La cantidad mínima de depósito es de 3.000 USDT. <br> El monto máximo de depósito es 10.000.000 USDT.',
    group1description2: '1% de tarifa de depósito más hasta 1% de tarifa de crecimiento, depende del día de la semana que invertido y cuántos días quedan para el crecimiento del token FIX',
    group1description3: 'El depósito se realiza hasta un máximo de 24 horas.',
    group1description4: 'Para retirar fondos, vaya a su área personal y haga clic en la pestaña "Retiro". El retiro se realiza enviando USDT (Tether, Tron) a su Billetera.',
    group1description5: 'La cantidad mínima de retiro es 50 USDT. El monto máximo de retiro es 1,000,000 USDT por transacción.',
    group1description6: 'No hay tarifa de retiro de la empresa, solo paga la red blockchain tarifa por la transferencia.',
    group1description7: 'La solicitud de retiro se procesa dentro de las 24 horas.',
    group2Title0: 'Qué bonificación puedo obtener por invitar a un inversor?',
    group2Title1: 'Qué es el Pool de líderes?',
    group2Title2: 'Cómo entro en el Pool del 3% y 2%??',
    group2Title3: 'Qué otras bonificaciones hay?',
    group2Title4: 'Cuándo se obtienen las bonificaciones sobre los ingresos de un inversor?',
    group2description0: 'Por invitar a un nuevo inversor, recibe una bonificación única del 3% de la inversión Monto. Y luego el 10% de los ingresos del inversor invitado (siempre que su FIX cuenta de token es de al menos $ 1,000).',
    group2description1: 'Este es un bono, que es el 5% de los ingresos totales del fondo y se paga mensualmente. Eso se divide en 2 categorías: 3% y 2%.',
    group2description2: 'Para ingresar en el Pool al 3% se deben cumplir las siguientes condiciones: Hacer una facturación con el equipo en cinco niveles de 800 000 USDT. La inversión personal debe estar en al menos 5000 USDT. <br> Para ingresar a la alberca por otro 2% se deben cumplir las siguientes condiciones: Realizar una facturación de 1 000 000 USDT con el equipo en cinco niveles. Inversión personal debe ser de al menos 10000 USDT.',
    group2description3: `Bonificaciones sobre los ingresos del inversor (semanal). <br>
        1 línea - 10% de los ingresos del inversor (con inversión personal de al menos 1 000 USDT). <br>
        2 línea - 5% de los ingresos del inversor (con una inversión personal de al menos 2 500 USDT). <br>
        Línea 3: 3% de los ingresos del inversor (sobre una inversión personal de al menos 5000 USDT). <br>
        4ta línea: 2% de los ingresos del inversor (sobre una inversión personal de al menos 7.500 USDT). <br>
        Línea 5 - 1% de los ingresos del inversionista (con una inversión personal de al menos 10,000 USDT).`,
    group2description4: 'Todos los domingos se acumula una bonificación semanal de los ingresos del inversor.',
    group3Title0: 'Cuál es el crecimiento del token FIX asegurado?',
    group3Title1: 'Cuándo sube el precio de un token FIX?',
    group3Title2: 'Por qué el token FIX solo crece un 1% por semana??',
    group3description0: 'El crecimiento fijo del token FIX se debe al crecimiento de INSTANT CASH Capital el capital total del fondo de inversión a través del comercio en los mercados de divisas, acciones y criptomonedas.',
    group3description1: 'El precio del token FIX crece un 1% todos los sábados.',
    group3description2: 'El contrato inteligente proporciona un crecimiento de token semanal del 1%.',
    group4Title0: 'Qué es INSTANT CASH Capital?',
    group4Title1: 'Que esta haciendo la empresa?',
    group4Title2: 'Dónde está registrado INSTANT CASH Capital?',
    group4description0: 'INSTANT CASH Capital - CryptoFund de inversión cuántica.',
    group4description1: 'Proporciona operaciones comerciales en mercados de divisas, acciones y criptomonedas.',
    group4description2: 'INSTANT CASH Capital LLC. está registrada con el número de registro 834 LLC 2021 en la jurisdicción de San Vicente y las Granadinas y su domicilio social es Suite 305, Griffith Corporate Center, Apartado de correos 1510, Beachmont, Kingstown, St. Vicente y las Granadinas.',
    stillQuestionTitle: 'Todavía tienes alguna pregunta?',
    stillQuestionDescription: 'Si no puede encontrar una pregunta en nuestras preguntas frecuentes, siempre puede contactarnos. Te responderemos a la brevedad!',
    telegramManagerTitle: 'INSTANT CASH Manager en Telegram',
    telegramManagerDescription: 'Siempre estamos felices de ayudar!',
    emailManagerDescription: 'La mejor manera de obtener una respuesta más rápido!',
  }
}
