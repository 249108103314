export default {
  en: {
    myOffice: 'My office',
    deposit: 'Deposit',
    INSTANTTokenCheck: 'INSTANT token Account',
    partnershipAccount: 'Partnership Account',
    transactions: 'Transactions',
    withdrawl: 'Withdrawal',
    partners: 'Partners',
    settings: 'Settings',
    globalpool: 'Global Pool',
    cryptohouse: 'ProINSTANTHome',
    northencyprus: 'Northen Cyprus',
    spain: 'Spain',
    marketing: 'Marketing Tools',
    manager: 'Personal Manager',
    partnerAgreements: 'Partner Agreements',
    withdraw: 'Withdrawal',
    adminDashboard: 'Admin Dashboard',
    manageUsers: 'Manage Users',
    deposits: 'Deposits',
    promotion: 'Promotion',
    verifications: 'Verifications'
  },
  ru: {
    myOffice: 'Мой офис',
    deposit: 'Депозит',
    INSTANTTokenCheck: 'INSTANT токен счет',
    partnershipAccount: 'Партнерский аккаунт',
    transactions: 'Транзакции',
    withdrawl: 'Вывод',
    partners: 'Партнеры',
    settings: 'Настройки',
    globalpool: 'Globapool',
    cryptohouse: 'ProINSTANTHome',
    northencyprus: 'Северный Кипр',
    spain: 'Испания',
    marketing: 'Маркетинг',
    manager: 'Персональный Менеджер',
    partnerAgreements: 'Партнерское соглашение',
    withdraw: 'Транзакции',
    adminDashboard: 'Панель управления',
    manageUsers: 'Пользователи',
    deposits: 'Депозиты',
    promotion: 'Promotion',
    verifications: 'Верификации'
  },
  es: {
    myOffice: 'Mi Oficina',
    deposit: 'Depositar',
    INSTANTTokenCheck: 'Cuenta INSTANT Token',
    partnershipAccount: 'Cuenta de Asociación',
    transactions: 'Transacciones',
    withdrawl: 'Retiro',
    partners: 'Socios',
    settings: 'Ajustes',
    globalpool: 'Globapool',
    cryptohouse: 'ProINSTANTHome',
    northencyprus: 'Chipre del Norte',
    spain: 'España',
    marketing: 'Herramientas de Marketing',
    manager: 'Gerente de Personal',
    partnerAgreements: 'Acuerdos de Socios',
    withdraw: 'Transacciones',
    adminDashboard: 'Panel de Administración',
    manageUsers: 'Administrar los Usuarios',
    deposits: 'Depósitos',
    promotion: 'Promotion',
    verifications: 'Verificaciones '
  }
}
