import {
  ChartPieIcon,
  CogIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
  ShieldExclamationIcon,
  UsersIcon,
  CollectionIcon,
  DesktopComputerIcon,
  DatabaseIcon,
  UserAddIcon,
  CakeIcon,
} from '@heroicons/vue/outline';
import HomeIcon from '@/components/icons/HomeIcon.vue'
import DepositIcon from '@/components/icons/DepositIcon.vue'
import FixTokenAccountIcon from '@/components/icons/FixTokenAccountIcon.vue'
import PartnershipAccountIcon from '@/components/icons/PartneshipAccountIcon.vue'
import TransactionsIcon from '@/components/icons/TransactionsIcon.vue'
import WithdrawIcons from '@/components/icons/WithdrawIcons.vue'
import PartnersIcon from '@/components/icons/PartnersIcon.vue'
import GlobalPoolIcon from '@/components/icons/GlobalPoolIcon.vue'
import MarketingToolsIcon from '@/components/icons/MarketingToolsIcon.vue'
import PersonalManagerIcon from '@/components/icons/PersonalManagerIcon.vue'
import PartnerAgreementsIcon from '@/components/icons/PartnerAgreementsIcon.vue'
import FaqIcon from '@/components/icons/FaqIcon.vue'
import ProfixHomeIcon from '@/components/icons/ProfixHomeIcon.vue'
import FixAcademyIcon from '@/components/icons/FixAcademyIcon.vue'
import GlobalNftIcon from '@/components/icons/GlobalNftIcon.vue'

export default {
  state: {
    navigation: [
      { name: 'myOffice', href: '', icon: HomeIcon },
      { name: 'deposit', href: 'deposit', icon: DepositIcon },
      { name: 'withdrawl', href: 'withdraw', icon: WithdrawIcons },
      { name: 'manager', href: 'personalManager', icon: PersonalManagerIcon },
    ],
    userNavigation: [
      { name: 'settings', href: '/settings', icon: CogIcon },
      // { name: '2FASecurity', href: '/two-factor', icon: ShieldExclamationIcon },
      { name: 'documents', href: '/documents', icon: DocumentTextIcon },
      // { name: 'partners', href: '/partners', icon: UsersIcon },
      { name: 'faq', href: '/faq', icon: QuestionMarkCircleIcon },
      { name: 'logout', href: '#', icon: LogoutIcon },
    ],
    navigationAdmin: [
      { name: 'adminDashboard', href: 'admin', icon: DesktopComputerIcon },
      { name: 'manageUsers', href: 'admin/users', icon: CogIcon },
      { name: 'deposits', href: 'admin/deposits', icon: CurrencyDollarIcon },
      { name: 'withdrawLink', href: 'admin/withdraw', icon: CollectionIcon },
      { name: 'transactions', href: 'admin/transactions', icon: DatabaseIcon },
      { name: 'promotion', href: 'admin/promotion', icon: ChartPieIcon },
      { name: 'verifications', href: 'admin/verification', icon: UserAddIcon },
      { name: 'Birthdays', href: 'admin/birthdays', icon: CakeIcon },
    ]
  },
  getters: {
    navigation(state) {
      return state.navigation
    },
    userNavigation(state) {
      return state.userNavigation
    },
    getAdminNavigation(state) {
      return state.navigationAdmin
    }
  }
}
