import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
  },
  actions: {
    fetchTransactions(_, params) {
      return new Promise((res, rej) => {
        axios.get(`${url}/api/payments/transaction?page=${page}&type[]=0&count=20`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
          .then((response) => {
            res(response.data.data)
          })
          .catch((err) => {
            rej(err)
          })
      })
    }
  }
}
