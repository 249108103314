export default {
  en: {
    withdrawType: 'WITHDRAW',
    depositType: 'DEPOSIT',
    exchangeType: 'EXCHANGE',
    directBonusType: 'DIRECT BONUS',
    weeklyBonusType: 'WEEKLY BONUS',
    globalPoolGoldType: 'GLOBAL POOL GOLD',
    globalPoolDiamondType: 'GLOBAL POOL DIAMOND',
    depositFixBonusType: 'DEPOSIT INSTANT BONUS',
    depositUsdBonusType: 'DEPOSIT USD BONUS',
    withdrawFixBonusType: 'WITHDRAW INSTANT BONUS',
    withdrawUsdBonusType: 'WITHDRAW USD BONUS',
    depositFixGoldType: 'DEPOSIT INSTANT GOLD',
    autoExchangeType: 'AUTO EXCHANGE',
    withdrawFixGoldType: 'WITHDRAW INSTANT GOLD',
    exchangeFixGoldType: 'EXCHANGE INSTANT GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'EXCHANGE INSTANT ONE',
    viewTransaction: 'View',
    previousArrow: 'Previous',
    nextArrow: 'Next',
    noData: 'No data available in table',
    withdrawVideo: 'https://www.youtube.com/embed/GFlM1c-BIwY',
    declined: 'declined',
    declinedByUser: 'Declined by user',
  },
  ru: {
    withdrawType: 'ВЫВОД',
    depositType: 'ДЕПОЗИТ',
    exchangeType: 'ОБМЕН',
    directBonusType: 'ЛИЧНЫЙ БОНУС',
    weeklyBonusType: 'НЕДЕЛЬНЫЙ БОНУС',
    globalPoolGoldType: 'GLOBAL POOL GOLD',
    globalPoolDiamondType: 'GLOBAL POOL DIAMOND',
    depositFixBonusType: 'ДЕПОЗИТ INSTANT БОНУС',
    depositUsdBonusType: 'ДЕПОЗИТ USD БОНУС',
    withdrawFixBonusType: 'ВЫВОД INSTANT БОНУС',
    withdrawUsdBonusType: 'ВЫВОД USD БОНУС',
    depositFixGoldType: 'ДЕПОЗИТ INSTANT GOLD',
    autoExchangeType: 'АВТО ОБМЕН',
    withdrawFixGoldType: 'ВЫВОД INSTANT GOLD',
    exchangeFixGoldType: 'ОБМЕН INSTANT GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'ОБМЕН INSTANT ONE',
    viewTransaction: 'Смотреть',
    previousArrow: 'Назад',
    nextArrow: 'Вперед',
    noData: 'Данные в таблице отсутствуют',
    withdrawVideo: 'https://www.youtube.com/embed/Yi71rKJH6Ac',
    declined: 'отклонен',
    declinedByUser: 'Отклонено пользователем',
  },
  es: {
    withdrawType: 'RETIRO',
    depositType: 'DEPOSITAR',
    exchangeType: 'INTERCAMBIO',
    directBonusType: 'BONIFICACIÓN DIRECTA',
    weeklyBonusType: 'BONIFICACIÓN SEMANAL',
    globalPoolGoldType: 'GLOBAL POOL ORO',
    globalPoolDiamondType: 'GLOBAL POOL DIAMANTE',
    depositFixBonusType: 'CARGAR BONIFICACIÓN FIX',
    depositUsdBonusType: 'CARGAR BONIFICACIÓN USD',
    withdrawFixBonusType: 'RETIRAR BONIFICACIÓN FIX',
    withdrawUsdBonusType: 'RETIRAR BONIFICACIÓN USD',
    depositFixGoldType: 'DEPOSIT INSTANT GOLD',
    autoExchangeType: 'AUTO EXCHANGE',
    withdrawFixGoldType: 'WITHDRAW INSTANT GOLD',
    exchangeFixGoldType: 'INTERCAMBIO INSTANT GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'EXCHANGE INSTANT ONE',
    viewTransaction: 'Ver',
    previousArrow: 'Anterior',
    nextArrow: 'Siguiente',
    noData: 'No hay datos disponibles en la tabla',
    withdrawVideo: 'https://www.youtube.com/embed/U29Uai6oyxY',
    declined: 'declinado',
    declinedByUser: 'Rechazado por el usuario',
  }
}
