<template>
    <transition-group name="skeleton" mode="out-in">
      <div class="px-4 sm:px-6 md:px-8">
        <div :key="1" class="card flex gap-4">
          <div class="card-bg skeleton skeleton--wave h-96"></div>
          <div class="card-bg skeleton skeleton--wave h-96"></div>
        </div>
        <div :key="2" class="card flex gap-4 mt-4">
          <div class="card-bg skeleton skeleton--wave h-96"></div>
          <div class="card-bg skeleton skeleton--wave h-96"></div>
        </div>
        <div :key="3" class="card-bg skeleton skeleton--wave h-96 mt-4" />
      </div>
    </transition-group>
</template>
