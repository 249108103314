import { createRouter, createWebHistory } from 'vue-router'

const safeVerify = () => {
  if (!localStorage.getItem('verify')) {
    localStorage.setItem('verify', document.location.href)
  }
}

const routes = [
  {
    path: '/',
    beforeEnter: () => ({ path: '/login' })
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/AuthorizationPage.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/RegistrationPage.vue'),
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/ResetPasswords.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPasswordConfirmation',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/resetPasswordConfirmation.vue'),
  },
  {
    path: '/two-factor',
    name: 'twoFactor',
    component: () => import(/* webpackChunkName: "two-factor" */ '../views/auth/TwoFactorConfirm.vue'),
  },
  {
    path: '/api/email/verify',
    beforeEnter: safeVerify()
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  }
]

const authRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default authRouter;
