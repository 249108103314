export default {
  en: {
    passwordChanged: 'password changed!',
    avatarDeleted: 'Avatar has been deleted',
    addWalletsWarning: `We ask you to attach your personal FIX wallet in order to receive tokens on the blockchain. <br>
    This action is mandatory for investors who have more than 2500 FIX tokens in their account. <br>
    Go to the Wallets section. <br>
    If you have any questions, please contact the Support Service. <br>
    Sincerely, INSTANT CASH Capital team. <br>`,
    toSettings: 'go to settings',
    important: 'Important!',
  },
  ru: {
    passwordChanged: 'Пароль успешно изменен!',
    avatarDeleted: 'Аватар был удален',
    addWalletsWarning: `Просим Вас прикрепить свой личный FIX кошелёк, для того чтобы получить токены на блокчейне. <br>
    Данное действие является обязательным для инвесторов у кого более 2500 FIX токенов на счету. <br>
    Перейдите в раздел Кошельки. <br>
    По возникшим вопросам обращайтесь в Службу Поддержки. <br>
    С уважением, команда INSTANT CASH Capital. <br>`,
    toSettings: 'перейти в настройки',
    important: 'Важно!',
  },
  es: {
    passwordChanged: 'contraseña cambiada!',
    avatarDeleted: 'El avatar ha sido eliminado',
    addWalletsWarning: `Le pedimos que adjunte su monedero personal FIX para poder recibir tokens en la blockchain. <br>
    Esta acción es obligatoria para los inversores que tengan más de 2500 tokens FIX en su cuenta. <br>
    Vaya a la sección Monederos. <br>
    Si tiene alguna pregunta, póngase en contacto con el Servicio de Asistencia. <br>
    Atentamente, equipo de INSTANT CASH Capital. <br>`,
    toSettings: 'ir a configuración',
    important: 'Importante',
  },
}
