import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
    fixOneChangeRate: {
      price: 0,
      days: null
    },
    fixGoldChangeRate: {
      price: 0,
      days: null
    },
    daysGrowth: 3,
    walletBlockchains: [
      {
        id: 1, name: 'TRC20', value: 1, unavailable: false
      },
      {
        id: 2, name: 'ERC20', value: 15, unavailable: false
      },
    ]
  },
  getters: {
    getFixOneChangeRate(state) {
      return state.fixOneChangeRate
    },
    getFixGoldChangeRate(state) {
      return state.fixGoldChangeRate
    },
    getWalletBlockchains(state) {
      return state.walletBlockchains
    }
  },
  mutations: {
    updPrices(state, payload = {}) {
      state.fixOneChangeRate = { ...payload.one }
      state.fixGoldChangeRate = { ...payload.gold }
    }
  },
  actions: {
    async getPrice(context) {
      await axios.get(`${url}/api/common/tokens`).then((response) => {
        context.commit('updPrices', response.data)
      })
    }
  }
}
