<template>
  <div class="flex justify-center items-center">
    <div class="w-96 h-100 sm:h-full flex flex-col gap-3 card-bg rounded-md m-4 text-gray-300">
      <div class="flex justify-between">
        <the-language class="self-end" />
      </div>
      <img :src="logo" alt="logo" class="w-2/3 mx-auto mt-6" @click="$router.push('/')">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import TheLanguage from '@/components/TheLanguage.vue'
import { useI18n } from 'vue-i18n'
import { computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const { t } = useI18n()
const store = useStore()
const logo = computed(() => store.state.theme === 'dark' ? require('../assets/dark/logo-dark.png') : require('../assets/light/logo-light.png'))
onBeforeMount(() => {
  const queryString = document.location.search
  store.commit('changeTheme')
  const r = queryString.split('=')[1]
  const query = queryString.split('=')[0]
  if (query === '?r') {
    useRouter().push({
      name: 'Registration',
      query: { r }
    })
  }
})
</script>
