<template>
  <button
    @click="changeOpenTable"
    :class="[
          'relative grid grid-cols-custom-3 grid-rows-1',
          `sm:grid-cols-custom-${Object.keys(props.trans).length < 4 ? Object.keys(props.trans).length : 4 }`,
          `lg:grid-cols-custom-${Object.keys(props.trans).length < 5 ? Object.keys(props.trans).length : 5 }`,
          `xl:grid-cols-custom-${Object.keys(props.trans).length < 6 ? Object.keys(props.trans).length : 6 }`,
          props.trans.data ? `2xl:grid-cols-custom-${Object.keys(props.trans).length - 1 }` : `2xl:grid-cols-custom-${Object.keys(props.trans).length }`,
          'justify-items-start rounded-md m-1 text-main bg-[#ebf1f6] dark:bg-[#272727] items-center gap-x-3'
          ]"
  >
    <div
      :class="[
        'text-main text-2xl font-bold w-full h-16 flex items-center justify-center bg-[#bdd1e8] dark:bg-[#3d3d3d] rounded-md',
        depositStatuses.find(el => el === props.trans.type) ? '!text-green-500 border-2 border-green-500' : '',
        withdrawStatus.find(el => el === props.trans.type) ? '!text-main-red border-2 border-main-red' : '',
        // костыль надо фиксить
        props.trans.type === 0 ? '!text-green-500 border-2 border-green-500' : ''
        ]">
      {{ $route.path === '/partnershipAccount' || $route.path === '/partners' ? '' : '$' }}
      <PersonalManagerIcon v-if="$route.path === '/partnershipAccount' || $route.path === '/partners'" class="mr-0"/>
    </div>
    <template v-for="(value, key, index) in props.trans">
      <type-column :key="key + index" :type="value" :date="props.trans.data" v-if="key === 'type'"/>
      <actions-cell
        v-else-if="key === 'id'"
        :key="key + index"
        :class="[
          'items-center gap-2',
          index === props.tableTitles.length ? 'justify-self-center' : '',
          // index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : ''
          ]"
        :value="value"
        :status="props.trans.status"
        :id="props.trans.id"
        :type="props.trans.type"
        @deleteTransaction="$emit('deleteTransaction', 1)"
      />
      <div v-else-if="key === 'idUser'" :key="key + index" :class="['flex items-center gap-2', index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : '']">
        <router-link :to="'/admin/users/' + value" :id="value" >
          <CogIcon class="w-5 h-5 text-gray-400 hover:text-green-600"/>
        </router-link>
      </div>
      <p v-else-if="key === 'admin' || key === 'sponsor'" :key="key + index" :class="[index + 1 === props.tableTitles.length ? 'justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:inline-block' : index === 2 ? 'hidden lg:inline-block' : index === 3 ? 'hidden xl:inline-block' : index >= 4 ? 'hidden 2xl:inline-block' : '']">
        <CheckIcon v-if="value === '+'" class="w-5 h-5 text-gray-400 text-green-600" />
        <XIcon v-else class="w-5 h-5 text-gray-400 text-red-600" />
      </p>
      <p v-else-if="key === 'actionsWithUser'" :key="key + index" :class="['justify-self-center gap-2', index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : '']">
        <CheckCircleIcon class="w-6 h-6 text-gray-400 hover:text-green-600"/>
        <XCircleIcon class="w-6 h-6 text-gray-400 hover:text-red-600"/>
      </p>
      <copy-text v-else-if="key === 'idTransaction' || key === 'usdtWallet'" :value="value" :id="props.trans.idTransaction" :key="key+index" :class="['w-24',index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:inline-block' : index === 2 ? 'hidden lg:inline-block' : index === 3 ? 'hidden xl:inline-block' : index >= 4 ? 'hidden 2xl:inline-block' : '']" />
      <div v-else-if="key === 'actionsWithTransaction'" :key="key + index" :class="['items-center gap-2', index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:inline-block' : index === 2 ? 'hidden lg:inline-block' : index === 3 ? 'hidden xl:inline-block' : index >= 4 ? 'hidden 2xl:inline-block' : '']">
        <CheckCircleIcon class="w-5 h-5 text-gray-400 hover:text-green-600"/>
        <TrashIcon class="w-5 h-5 text-gray-400 hover:text-red-600"/>
      </div>
      <p v-else-if="key === 'total'" :key="key + index" :class="['flex items-center gap-2', index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : '']">
        <span>{{ value }}</span>
        <PencilIcon class="w-5 h-5 text-gray-400 hover:text-green-600"/>
      </p>
      <p
        v-else-if="key === 'dollars'"
        :key="key + index"
        :class="[
          '!flex items-center justify-self-end sm:justify-self-start mr-4 sm:mr-0 gap-2 text-lg 2sm:text-xl font-bold',
          index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '',
          index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : '',
          depositStatuses.find(el => el === props.trans.type) ? '!text-green-500' : '',
          withdrawStatus.find(el => el === props.trans.type) ? '!text-main-red' : '',
          // костыль надо фиксить
          props.trans.type === 0 ? '!text-green-500' : ''
          ]"
      >$ {{ value ?? 0 }}</p>
      <p v-else-if="key === 'token'" :key="key + index" :class="['flex items-center gap-2 text-xl font-light', index + 1 === props.tableTitles.length ? '!flex justify-self-center' : '', index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:flex' : index === 2 ? 'hidden lg:flex' : index === 3 ? 'hidden xl:flex' : index >= 4 ? 'hidden 2xl:flex' : '']">{{ value ?? 0 }} FIX</p>
      <p
        v-else-if="key !== 'data'"
        :key="key + index"
        :class="[
          index === 0 ? 'text-left flex' : index === 1 ? 'hidden sm:inline-block' : index === 2 ? 'hidden lg:inline-block' : index === 3 ? 'hidden xl:inline-block' : index >= 4 ? 'hidden 2xl:inline-block' : '',
          index + 1 === props.tableTitles.length ? 'hidden sm:!flex justify-self-center sm:accent-inherit' : '',
          key === 'userLevel' ? '!hidden justify-self 2xl:!flex' : '',
          statusName[value] === 'pending' && key === 'status'  ? 'bg-orange-400 rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center' : statusName[value] === 'created' && key === 'status' ? 'bg-gray-400 rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center' : statusName[value] === 'completed' && key === 'status' ? 'bg-[#76cc72] dark:bg-[#88be71] rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center': statusName[value] === 'waitVerification' && key === 'status' ? 'bg-orange-400 rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center' : statusName[value] === 'declined' && key === 'status' ? 'bg-[#f66e6e] rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center' : statusName[value] === 'Declined by user' && key === 'status' ? 'bg-[#f66e6e] rounded-lg text-white text-base p-2 mr-4 2xl:mr-0 w-full xl:w-52 justify-center' : '',
          ]">
        {{ key === 'status' ? t(statusName[value]) : value }}
      </p>
    </template>
    <div class="col-span-4 row-start-2 text-left w-full text-main flex" v-show="isOpenTable">
      <div class="w-[60px] bg-white dark:bg-[#171717]"></div>
      <div class="pl-3">
        <template v-for="(item, index) in tableTitle" :key="'more' + index">
          <p
            :class="[
          'flex items-center p-1',
          index === 0 ? 'hidden' : index === 1 ? 'sm:hidden py-2' : index === 2 ? 'lg:hidden py-2' : index === 3 ? 'xl:hidden py-2' : index >= 4 ? '2xl:hidden' : '',
          index + 1 === tableTitle.length ? 'hidden' : '',
          ]"
          >
            <span class="mr-4 font-bold text-xxs">{{ t(tableTitle[index].name) }}: </span>
            <span v-if="item.name === 'statusTransaction'" :class="[props.trans.status === 0 ? 'bg-gray-400' : props.trans.status === 100 ? 'bg-green-600' : props.trans.status === -20 || props.trans.status === -30 ? 'bg-[#f66e6e]' : 'bg-orange-400',
            'rounded-xl text-xxs p-1 px-2 text-white']">
            {{ t(statusName[props.trans.status])}}
            </span>
            <span v-else-if="item.name === 'user'">
             <router-link :to="'/admin/users/' + Object.values(props.trans).find((i, idx) => idx === index)" :id="Object.values(props.trans).find((i, idx) => idx === index)">
              <CogIcon class="w-5 h-5  hover:text-green-600"/>
             </router-link>
          </span>
            <span v-else-if="item.name === 'dollarsTransaction'" class="text-xs">
              {{ props.trans.dollars ?? '0' }} USD
            </span>
            <span v-else-if="item.name === 'admin' || item.name === 'sponsor'">
            <CheckIcon v-if="Object.values(props.trans).find((i, idx) => idx === index) === '+'" class="w-5 h-5 hover:text-green-600" />
            <XIcon v-else class="w-5 h-5 hover:text-red-600" />
          </span>
            <span v-else-if="item.name === 'dataTransaction'" class="text-xs leading-5">
              {{ props.trans.data.split('-').join('.').split(' ')[0].split('.').reverse().join('.') }}
              {{ props.trans.data.split(' ')[1] }}
            </span>
            <copy-text v-else-if="item.name === 'USDT Wallet'" :value="Object.values(props.trans).find((i, idx) => idx === index)" @click.stop/>
            <span v-else :class="item.name === 'FIXTransaction' ? 'text-sm' : ''">
            {{ Object.values(props.trans).find((i, idx) => idx === index) === props.trans.dollars ? '' : Object.values(props.trans).find((i, idx) => idx === index) }}
            {{ item.name === 'FIXTransaction' ? 'FIX' : '' }}
          </span>
            <!--        eslint-disable-next-line -->
          </p>
        </template>
      </div>
    </div>
  </button>
</template>

<script setup>
import {
  CogIcon,
  TrashIcon,
  CheckIcon,
  XIcon,
  XCircleIcon,
  CheckCircleIcon,
  PencilIcon
} from '@heroicons/vue/outline';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CopyText from '@/components/UI/copyText.vue'
import ActionsCell from '@/components/UI/TableCells/actionsCell.vue'
import PersonalManagerIcon from '@/components/icons/PersonalManagerIcon.vue'
import TypeColumn from './UI/TableCells/typeColumn.vue'

const props = defineProps({
  trans: Object,
  tableTitles: Array
})
const tableTitle = ref([...props.tableTitles])
onBeforeMount(() => {
  if (props.trans.data) {
    tableTitle.value.splice(props.tableTitles.length - 2, 0, { name: 'dataTransaction' })
  }
})
const isOpenTable = ref(false)
const changeOpenTable = () => {
  if (window.innerWidth < 1536) {
    isOpenTable.value = !isOpenTable.value
  }
}
const { t } = useI18n()
/* eslint-disable */
const statusName = ref({
  '10': 'pending',
  '5': 'waitVerification',
  '1': 'pending',
  '0': 'created',
  '100': 'completed',
  '-20': 'declinedByUser',
  '-30': 'declined'
})

const depositStatuses = ref([0, 3, 4, 5, 6, 7, 8, 11, 15])
const withdrawStatus = ref([1, 9, 10, 13, 14])

</script>
