import axios from 'axios'
import { url } from '@/main'

export default {
  state: {
    userInfo: {},
    stats: {}
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo
    },
    getUserName(state) {
      return state.userInfo.first_name ? `${state.userInfo.first_name} ${state.userInfo.last_name}` : 'User Name'
    },
    getRole(state) {
      return state.userInfo.is_admin ? 'admin' : 'investor'
    },
    getAvatar(state) {
      return url + state.userInfo.photo
    },
    getFixOneBalance(state) {
      return state.userInfo.fix_balance + state.userInfo.fix_internal_balance + state.userInfo.fix_bonus_balance ?? 0
    },
    getFixGoldBalance(state) {
      return state.userInfo.fix_gold_balance + state.userInfo.fix_gold_internal_balance + state.userInfo.fix_gold_bonus_balance ?? 0
    },
    getStats(state) {
      return state.stats
    }
  },
  mutations: {
    addUserInfo(state, data) {
      state.userInfo = data
    },
    addStats(state, data) {
      state.stats = data
    }
  },
  actions: {
    logout() {
      axios.post(`${url}/logout`, {}, { withCredentials: true }).then(() => {
        document.location.href = document.location.origin
      })
    },
    createApiToken() {
      axios.post(`${url}/api/tokens/create`, {}, { withCredentials: true }).then(() => {
        document.location.href = document.location.origin
      })
    },
    clearCookie() {
      const setCookie = (name, value, options = {}) => {
        /* eslint-disable */
        options = {
          path: '/',
          // при необходимости добавьте другие значения по умолчанию
          ...options
        }

        if (options.expires instanceof Date) {
          options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }

        document.cookie = updatedCookie;
      }
      setCookie('laravel_session', "", {
        'max-age': -1
      })
      setCookie('X-XSRF-TOKEN', "", {
        'max-age': -1
      })
      setCookie('XSRF-TOKEN', "", {
        'max-age': -1
      })
    },
    fetchUserInfo({ commit }) {
      axios.get(`${url}/api/user`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          Accept: 'application/json'
        }
      }).then((response) => {
        commit('addUserInfo', response.data)
      })
    },
    fetchStats({ commit }) {
      axios.get(`${url}/api/profile/info/stats`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          Accept: 'application/json'
        }
      }).then((response) => {
        commit('addStats', response.data.data)
      })
    },
  }
}
