export default {
  en: {
    globalPoolWelcomeNull: 'Welcome to the Global Pool',
    globalPoolGetDividends: 'Do it and receive dividends from a Global Company',
    globalPoolWelcomeP1: 'Congratulations',
    globalPoolWelcomeP2: 'You are now part of the',
    claimed: 'You already claimed $ {claim} in GlobalPool.',
    globalPoolTransactions: 'Global Pool Transactions',
    contacts: 'Contacts',
    contactDescription: 'ProfixHome Representative - Northen Cyprus',
    contactUser: 'Elena Karpovskaya',
  },
  ru: {
    globalPoolWelcomeNull: 'Добро Пожаловать в Global Pool',
    globalPoolGetDividends: 'Выполняйте и получайте дивиденды от Глобальной компании',
    globalPoolWelcomeP1: 'Поздравляем',
    globalPoolWelcomeP2: 'Теперь Вы часть',
    claimed: 'Вы достигли $ {claim} в GlobalPool.',
    globalPoolTransactions: 'Global Pool Транзакции',
    contacts: 'Контакты',
    contactDescription: 'Представитель ProfixHome - Северный Кипр',
    contactUser: 'Елена Карповская',
  },
  es: {
    globalPoolWelcomeNull: 'Bienvenido a Global Pool ',
    globalPoolGetDividends: 'Hágalo y reciba dividendos de una Empresa Global',
    globalPoolWelcomeP1: 'Felicidades',
    globalPoolWelcomeP2: 'Ahora haces parte del',
    claimed: 'Ya reclamaste $ {claim} en GlobalPool.',
    globalPoolTransactions: 'Global Pool Transacciones',
    contacts: 'Contactos',
    contactDescription: 'Representante de ProfixHome - Chipre del Norte',
    contactUser: 'Elena Karpovskaya',
  }
}
