export default {
  en: {
    welcome: 'Welcome to INSTANT CASH!',
    signInDescription: 'Please sign in to your account',
    email: 'E-mail Address',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Login',
    forgotPassword: 'Forgot your password?',
    newOnPlatform: 'New on our platform?',
    createAccount: 'Create an account',
    registerAccount: 'Register your account',
    howRegister: 'How to Register?',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    phone: 'Phone',
    passwordConfirm: 'Confirm Password',
    refCode: 'Ref Code',
    privacyAgree: 'I agree to',
    privacyTitle: 'privacy policy & terms',
    tokenAgree: 'I agree with',
    tokenAgreeTitle: 'INSTANT Token Agreement',
    register: 'Register',
    haveAccount: 'Already have an account?',
    signIn: 'Sign In instead',
    sendResetLink: 'Send Password Reset Link',
    resetPassword: 'Reset Password',
    middleName: 'Middle Name',
    birthdate: 'Birthdate',
    nationality: 'Nationality',
    country: 'Country',
    state: 'State',
    city: 'City',
    addressStreet: 'Address',
    telegram: 'Telegram',
    incorrect: 'Incorrect email or password',
    fieldIsRequired: 'This field is required',
    twoFactorTitle: 'Enter code from the Google Authenticator app',
    incorrectCode: 'Incorrect code',
    longerThan8: 'Password must be longer than 8 characters',
    passwordNotMatch: 'Passwords do not match',
    checkAgreement: 'Please read and accept the agreement',
    languagesEmail: 'In what language do you want to receive letters?',
    verificationProfile: 'Verification your profile',
  },
  ru: {
    welcome: 'Добро Пожаловать в INSTANT CASH!',
    signInDescription: 'Пожалуйста, войдите в свой аккаунт',
    email: 'E-Mail Адрес',
    password: 'Пароль',
    rememberMe: 'Запомнить меня',
    login: 'Войти',
    forgotPassword: 'Забыли пароль?',
    newOnPlatform: 'Нет аккаунта?',
    createAccount: 'Создать аккаунт',
    registerAccount: 'Регистрация аккаунта',
    howRegister: 'Как зарегестрироваться?',
    firstName: 'Имя',
    lastName: 'Фамилия',
    gender: 'Пол',
    male: 'Мужчина',
    female: 'Женщина',
    phone: 'Телефон',
    passwordConfirm: 'Подтверждение пароля',
    refCode: 'Реферальный код',
    privacyAgree: 'Я согласен с',
    privacyTitle: 'политикой конфиденциальности и правилами',
    tokenAgree: 'Я согласен с',
    tokenAgreeTitle: 'FIX Token Соглашением',
    register: 'Зарегестрировать',
    haveAccount: 'Уже есть аккаунт?',
    signIn: 'Тогда войдите',
    sendResetLink: 'Отправить ссылку для сброса пароля',
    resetPassword: 'Сбросить пароль',
    middleName: 'Среднее имя',
    birthdate: 'Дата рождения',
    nationality: 'Национальность',
    country: 'Страна',
    state: 'Область',
    city: 'Город',
    addressStreet: 'Адрес',
    telegram: 'Telegram',
    incorrect: 'Неверная почта или пароль',
    fieldIsRequired: 'Это поле обязательно',
    twoFactorTitle: 'Введите код из приложения Google Authenticator',
    incorrectCode: 'Неверный код',
    longerThan8: 'Длина пароля должна быть больше 8 символов',
    passwordNotMatch: 'Пароли не совпадают',
    checkAgreement: 'Пожалуйста ознакомьтесь и примите соглашение',
    languagesEmail: 'На каком языке вы хотите получать письма?',
    verificationProfile: 'Верификация Вашего профиля',
  },
  es: {
    welcome: 'Bienvenido a INSTANT CASH!',
    signInDescription: 'Inicie sesión en su cuenta por favor',
    email: 'Dirección de correo electrónico',
    password: 'Contraseña',
    rememberMe: 'Recuérdame',
    login: 'Acceso',
    forgotPassword: 'Olvidaste tu Contraseña?',
    newOnPlatform: 'Nuevo en nuestra plataforma?',
    createAccount: 'Crea una Cuenta',
    registerAccount: 'Registra tu Cuenta',
    howRegister: '¿Cómo registrarse?',
    firstName: 'Primer Nombre',
    lastName: 'Apellido',
    gender: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    phone: 'Teléfono',
    passwordConfirm: 'Confirmar Contraseña',
    refCode: 'Código de Referencia',
    privacyAgree: 'Estoy de acuerdo con',
    privacyTitle: 'Política y Términos de Privacidad',
    tokenAgree: 'Estoy de acuerdo con',
    tokenAgreeTitle: 'FIX Token Convenio',
    register: 'Registrarse',
    haveAccount: 'Ya tienes una cuenta?',
    signIn: 'Iniciar sesión en su lugar',
    sendResetLink: 'Enviar enlace de restablecimiento de contraseña',
    resetPassword: 'Restablecer la contraseña',
    middleName: 'Segundo Nombre',
    birthdate: 'Fecha de Nacimiento',
    nationality: 'Nacionalidad',
    country: 'País',
    state: 'Estado',
    city: 'Ciudad',
    addressStreet: 'Dirección',
    telegram: 'Telegram',
    incorrect: 'Correo electrónico o contraseña incorrectos',
    fieldIsRequired: 'Este campo es necesario',
    twoFactorTitle: 'Introduzca el código desde la aplicación Google Authenticator',
    incorrectCode: 'Código incorrecto',
    longerThan8: 'La contraseña debe tener más de 8 caracteres',
    passwordNotMatch: 'Las contraseñas no coinciden',
    checkAgreement: 'Por favor, lea y acepte el acuerdo',
    languagesEmail: '¿En qué idioma quiere recibir las cartas?',
    verificationProfile: 'Verificación de su perfil',
  }
}
