import { createRouter, createWebHistory } from 'vue-router'
import DepositInfo from '../views/DepositInfo.vue'
import WithdrawInfo from '../views/WithdrawInfo.vue'
import ExchangeInfo from '../views/ExchangeInfo.vue'
import {
  isAdmin,
  isNotConfirmData,
  isNotFilledData,
  isNotVerifiedData,
  isNotVerifiedMail,
  isVerifiedMail
} from './checkRoles';

const routes = [
  {
    path: '/',
    name: 'MyOffice',
    component: () => import(/* webpackChunkName: "MyOffice" */ '../views/MyOffice.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/deposit',
    name: 'DepositPage',
    component: () => import(/* webpackChunkName: "DepositPage" */ '../views/DepositPage.vue'),
    meta: { verifiedMail: true, dataFilled: true },
    beforeEnter: [isNotVerifiedMail, isNotFilledData, isNotConfirmData, isNotVerifiedData]
  },
  {
    path: '/deposit/:id',
    name: 'DepositInfo',
    component: DepositInfo,
    props: true,
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/FIXTokenCheck',
    name: 'FIXTokenCheck',
    component: () => import(/* webpackChunkName: "FixTokenCheck" */ '../views/FIXTokenCheck.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/FIXTokenCheck/:id',
    name: 'FIXTokenCheckInfo',
    component: ExchangeInfo,
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/partnershipAccount',
    name: 'PartnershipAccount',
    component: () => import(/* webpackChunkName: "Partnership" */ '../views/PartnershipAccount.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/transactions',
    name: 'TransactionsPage',
    component: () => import(/* webpackChunkName: "Transactions" */ '../views/TransactionsPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/withdraw',
    name: 'WithdrawPage',
    component: () => import(/* webpackChunkName: "Withdraw" */ '../views/WithdrawPage.vue'),
    beforeEnter: [isNotVerifiedMail, isNotFilledData, isNotConfirmData]
  },
  {
    path: '/withdraw/:id',
    name: 'WithdrawInfo',
    component: WithdrawInfo,
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/partners',
    name: 'PartnersPage',
    component: () => import(/* webpackChunkName: "Partner" */ '../views/PartnersPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/globalpool',
    name: 'GlobalPool',
    component: () => import(/* webpackChunkName: "GlobalPool" */ '../views/GlobalPool.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/northencyprus',
    name: 'NorthenCyprus',
    component: () => import(/* webpackChunkName: "Cyprus" */ '../views/NorthenCyprus.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/spain',
    name: 'Spain',
    component: () => import(/* webpackChunkName: "Spain" */ '../views/SpainPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import(/* webpackChunkName: "Marketing" */ '../views/MarketingPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/personalManager',
    name: 'PersonalManager',
    component: () => import(/* webpackChunkName: "PersonalManager" */ '../views/PersonalManager.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/partnerAgreements',
    name: 'PartnerAgreements',
    component: () => import(/* webpackChunkName: "PartnerAgreements" */ '../views/PartnerAgreements.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "FAQ" */ '../views/FAQPage.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "Settings" */ '../views/SettingsPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/two-factor',
    name: '2FASecurity',
    component: () => import(/* webpackChunkName: "TwoFactor" */ '../views/TwoFactorPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import(/* webpackChunkName: "Documents" */ '../views/DocumentsPage.vue'),
    beforeEnter: [isNotVerifiedMail]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/AdminPage.vue'),
    children: [
      { path: '', component: () => import(/* webpackChunkName: "DashBoardAdmin" */ '../views/admin/DashboardPage.vue') },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "AdminManageUsers" */ '../views/admin/ManageUsersPage.vue'),
      },
      { path: 'users/:id', component: () => import(/* webpackChunkName: "AdminUserInfo" */ '../views/admin/UserInfoPage.vue'), props: true },
      { path: 'deposits', component: () => import(/* webpackChunkName: "AdminDeposit" */ '../views/admin/DepositPage.vue') },
      { path: 'withdraw', component: () => import(/* webpackChunkName: "AdminWithdraw" */ '../views/admin/WithdrawPage.vue') },
      { path: 'transactions', component: () => import(/* webpackChunkName: "AdminTransactions" */ '../views/admin/TransactionsPage.vue') },
      { path: 'global-pool', component: () => import(/* webpackChunkName: "AdminGlobalPool" */ '../views/admin/GlobalPoolPage.vue') },
      { path: 'promotion', component: () => import(/* webpackChunkName: "AdminPromotion" */ '../views/admin/PromotionPage.vue') },
      { path: 'verification', component: () => import(/* webpackChunkName: "AdminVerification" */ '../views/admin/VerificationPage.vue') },
      { path: 'birthdays', component: () => import(/* webpackChunkName: "BirthdaysPage" */ '../views/admin/BirthdaysPage.vue') },
    ],
    beforeEnter: [isAdmin]
  },
  {
    path: '/email_verify',
    name: 'EmailVerify',
    component: () => import(/* webpackChunkName: "EmailVerify" */ '../views/verification/EmailPage.vue'),
    beforeEnter: [isVerifiedMail]
  },
  {
    path: '/api/email/verify/:num/:key',
    name: 'ConfirmEmail',
    component: () => import(/* webpackChunkName: "EmailVerify" */ '../views/verification/ConfirmEmail.vue')
  },
  {
    path: '/data_verify',
    name: 'DataVerify',
    component: () => import(/* webpackChunkName: "DataVerify" */ '../views/verification/DataVerificationPage.vue'),
  },
  {
    path: '/confirm_documents',
    name: 'DocumentsConfirm',
    component: () => import(/* webpackChunkName: "DocumentsConfirm" */ '../views/verification/ConfirmDocumentsPage.vue'),
  },
  {
    path: '/verify_in_progress',
    name: 'VerifyProgress',
    component: () => import(/* webpackChunkName: "VerifyProgress" */ '../views/verification/VerifyInProgress.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
